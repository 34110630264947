import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { iif, MonoTypeOperatorFunction, Observable, of, Subject, throwError, TimeoutError } from 'rxjs';
import { catchError, delay, map, retryWhen, switchMap, tap, timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { API_ERRORS } from '../../constants/api-errors';
import { ApiErrorResponse } from '../../shared/models/api/response/api';
import { InterceptorOptions } from '../../shared/models/application/interceptor-options';
import { MainConfirmationModalService } from '../global-components/main-confirmation-modal/service/main-confirmation-modal.service';
import { ToastNotificationService } from '../global-components/toast-notification/service/toast-notification.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

type ErrorTypes = 'api' | 'timeout' | 'connection_refused' | 'retries' | 'other' | 'final';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	constructor(
		private toastService: ToastNotificationService,
		private mcmService: MainConfirmationModalService,
		private authService: AuthenticationService,
		private router: Router,
		@Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number
	) {
		this.catchError = this.catchError.bind(this);
		this.retryPipeline = this.retryPipeline.bind(this);
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		req = req.clone();

		const timeoutValue = Number(req.headers.get('timeout')) || this.defaultTimeout;
		const metaHeader = req.headers.get('meta') ? JSON.parse(req.headers.get('meta')) : {};
		const options: InterceptorOptions = Object.assign({}, this.getDefaultOptions(), metaHeader);

		if (req.headers.get('meta')) {
			req.headers.delete('meta');
		}

		return next.handle(req)
			.pipe(
				timeout(timeoutValue),
				map((event: HttpEvent<any>) => {
					return event;
				}),
				this.retryPipeline(options.retry)(),
				catchError(this.catchError(options))
			);
	}

	private getTranslateError(code: string): string {
		if (!code) {
			code = API_ERRORS.GENERAL_ERROR;
		}

		const findError = Object.keys(API_ERRORS).find(key => API_ERRORS[key] === code);

		return 'errors.api_errors.' + (findError || 'GENERAL_ERROR').toLowerCase().replace(/_[0-9]+$/, '');
	}

	private getDefaultOptions(): InterceptorOptions {
		return {
			timeout: this.defaultTimeout,
			retry: 2,
			showToastError: true,
			showToastRetriesError: true,
			toastErrorColor: 'danger'
		};
	}

	private showErrorModal(err: any, key: string): Subject<any> {
		const retrySubject = new Subject();

		this.mcmService.show({
			translate: {
				key
			},
			acceptButtonText: 'buttons.retry',
			cancelButtonText: 'buttons.cancel'
		}).then(action => {
			if (action.accepted) {
				retrySubject.next(err);
			} else {
				retrySubject.error(err);
			}
		});

		return retrySubject;
	}

	private retryPipeline(retries: number): () => MonoTypeOperatorFunction<HttpEvent<any>> {
		const errorsList: ({ type: ErrorTypes, error: any } | {})[] = [];

		return () => retryWhen(errors => errors.pipe(
			switchMap((e, i) => {
				if (retries === 0) {
					return throwError(e);
				}

				let errorKey: string = '';

				if (e instanceof TimeoutError) {
					errorsList.push({ type: 'timeout', error: e });
					errorKey = 'errors.server_timeout_error';
				} else if (e.error && e.status === 0) {
					errorKey = 'errors.server_connection_refused';
					errorsList.push({ type: 'connection_refused', error: (e as HttpErrorResponse).error });
				} else if (e.error && (e.error as ApiErrorResponse).code) {
					if (e.status === 500) {
						errorKey = 'errors.general_server_error';
						errorsList.push({ type: 'api', error: (e as HttpErrorResponse).error });
					} else {
						return throwError((e as HttpErrorResponse).error);
					}
				} else {
					errorsList.push(e);
				}

				return iif(
					() => i >= retries,
					throwError({ type: 'retries', error: errorsList[retries - 1] }),
					of(e).pipe(
						switchMap(v => this.showErrorModal(v, errorKey)),
						delay(1000)
					)
				);
			}),
			tap(() => !environment.production ? console.log('retrying...') : null)
		));
	}

	private catchError(options: InterceptorOptions): (err: { type: ErrorTypes, error: any }) => Observable<any> {
		return (err) => {
			let sendErr = Object.assign({}, err.error || err);

			if (!environment.production) {
				console.error('err--->>>', Object.assign({}, err));
			}

			if (err.type === 'retries') {
				if (options.showToastRetriesError) {
					this.toastService.show({
						type: 'warning',
						close: true,
						timeout: 6000,
						translate: {
							key: 'errors.timeout_retries_exceded'
						}
					});
				}
			}

			if (sendErr) {
				if (sendErr.type === 'api' || sendErr.type === 'timeout' || sendErr.type === 'connection_refused' || sendErr.code) {
					let key = this.getTranslateError(sendErr.code);

					if (sendErr.type === 'timeout') {
						sendErr = Object.assign({}, err.error);
						key = 'errors.timeout_error';
					} else if (sendErr.type === 'api') {
						sendErr = Object.assign({}, err.error);
					} else if (sendErr.type === 'connection_refused') {
						key = 'errors.connection_refused';
					}

					if (options.showToastError) {
						key = sendErr.message // Message sent from server
						if(key == 'El Token proporcionado ha expirado'){
							this.authService.destroySession();
							this.router.navigate(['login'])
						}
						this.toastService.show({
							type: options.toastErrorColor,
							close: true,
							timeout: 6000,
							translate: {
								key
							}
						});
					}
				}
			}

			if (!environment.production) {
				console.error('sendErr--->>>', sendErr);
			}

			return throwError(sendErr);
		}
	}
}
