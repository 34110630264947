import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MainConfirmationModalComponent } from './component/main-confirmation-modal.component';


@NgModule({
	declarations: [MainConfirmationModalComponent],
	imports: [
		CommonModule,
		FontAwesomeModule,
		TranslateModule,
		ModalModule.forRoot()
	],
	exports: [
		MainConfirmationModalComponent
	]
})
export class MainConfirmationModalModule { }
