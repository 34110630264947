import { Injectable } from '@angular/core';
import * as io from "socket.io-client";
import { environment } from "src/environments/environment";
import { Observable, Subject } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class SocketLogbookService {
  private endpoint = environment.apiUrl + '/historicals-scanner-building'
  private socket = io(environment.socket, { path: `${environment.socketContext}` });
  socketStatus: string = (environment.appName || '') + '-visitSocket';
  socketStatusDetection: Subject<boolean>;
  buildingId: number;
  lastRegister: any;
  changeDetection: Subject<any>;
  newRegisterDetection: Subject<any>;


  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {

    this.changeDetection = new Subject();
    this.newRegisterDetection = new Subject();
    this.socketStatusDetection = new Subject();
    console.log(environment.socket, 'socket-building-environment')
    console.log(this.socket)
  }

  scanner(): Subject<MessageEvent> {
    const idBuilding = (this.authService.getUser().buildingsRelation[0] && this.authService.getUser().buildingsRelation[0].buildingId) ?
    this.authService.getUser().buildingsRelation[0].buildingId :
    this.authService.getUser().enterprisesRelation[0].enterprise.building_id;
    let observable = new Observable(observer => {
      this.socket.on(`visitEnterprise/${idBuilding}`, data => {
        this.lastRegister = data;
        observer.next(data);
        this.changeDetection.next(data)
      });
      return () => {
        this.socket.close();
      };
    });

    // We define our Observer which will listen to messages
    // from our other components and send messages back to our
    // socket server whenever the `next()` method is called.
    let observer = {
      next: (data: any) => {
        this.socket.emit("scanner", data);
      }
    };

    // we return our Subject which is a combination
    // of both an observer and observable.
    return Subject.create(observer, observable);
  }

  scannerNewRegisterBitacora(): Subject<MessageEvent> {
    const idBuilding = (this.authService.getUser().buildingsRelation[0] && this.authService.getUser().buildingsRelation[0].buildingId) ?
    this.authService.getUser().buildingsRelation[0].buildingId :
    this.authService.getUser().enterprisesRelation[0].enterprise.building_id;
    let observable = new Observable(observer => {
      this.socket.on(`newRegisterBitacora/${idBuilding}`, data => {
        this.lastRegister = data;
        observer.next(data);
        this.newRegisterDetection.next(data)
      });
      return () => {
        this.socket.close();
      };
    });

    let observer = {
      next: (data: any) => {
        this.socket.emit("scanner", data);
      }
    };

    return Subject.create(observer, observable);

  }

  disconectSocket() {
    this.socket.close()
  }

  register() {
    return this.lastRegister;
  }

  updateAccess(id: string | number, access: string, idAdminEnterprise?: number | string): Observable<any> {
    return this.http.patch<any>(`${this.endpoint}/${id}`, { access, idAdminEnterprise }).pipe(
      map(res => {
        return res
      })
    )
  }

  getSatusSocket(): boolean {
    const value = localStorage.getItem(this.socketStatus);
    return value ? JSON.parse(value) : null;
  }

  setStatusSocket(status: boolean) {
    localStorage.setItem(this.socketStatus, JSON.stringify(status));
    this.socketStatusDetection.next(status);
  }
}
