import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import {TourMatMenuModule} from 'ngx-tour-md-menu';
@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		FontAwesomeModule,
		HttpClientModule,
		CoreModule,
		MalihuScrollbarModule.forRoot(),
		TourMatMenuModule.forRoot(),
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor() {
		library.add(fas);
	}
}
