import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { NoAuthGuard } from './core/guards/no-auth/no-auth.guard';

const routes: Routes = [{
	path: '',
	loadChildren: './core/template/full-layout-content/full-layout-content.module#FullLayoutContentModule',
	canActivateChild: [AuthGuard]
}, {
	path: '',
	loadChildren: './modules/auth/auth.module#AuthModule',
	canActivateChild: [NoAuthGuard]
}];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
