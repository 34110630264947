import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { MainAlert, MainAlertAction, MainAlertStream } from '../../../../shared/models/application/main-alert';

@Injectable({
	providedIn: 'root'
})
export class MainConfirmationModalService {
	alertSubject: Subject<MainAlertStream>;
	textLabels: string[] = ['title', 'body', 'acceptButtonText', 'cancelButtonText'];

	constructor(
		private translate: TranslateService
	) {
		this.alertSubject = new Subject();
	}

	show(obj: MainAlert): Promise<MainAlertAction> {
		return new Promise((resolve, reject) => {
			let translations: { [key: string]: string };
			if (typeof obj.translate === 'object') {
				const res = this.translate.instant(
					this.textLabels
						.map(key => obj.translate.key + '.' + key),
					obj.translate.params
				);

				translations = this.textLabels.reduce((prev, cur) => {
					if (res[obj.translate.key + '.' + cur] !== (obj.translate.key + '.' + cur)) {
						prev[cur] = res[obj.translate.key + '.' + cur];
					}
					return prev;
				}, {});
			} else {
				translations = {
					title: obj.title,
					body: obj.body,
					acceptButtonText: obj.acceptButtonText,
					cancelButtonText: obj.cancelButtonText
				};
			}

			obj = Object.assign(obj, translations);
			this.alertSubject.next({
				resolve,
				info: obj
			});
		});
	}
}
