import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalService, ModalDirective, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { MainAlert, MainAlertAction } from '../../../../shared/models/application/main-alert';
import { MainConfirmationModalService } from '../service/main-confirmation-modal.service';

@Component({
	selector: 'app-main-confirmation-modal',
	templateUrl: './main-confirmation-modal.component.html',
	styleUrls: ['./main-confirmation-modal.component.scss']
})
export class MainConfirmationModalComponent implements OnInit {
	@ViewChild(ModalDirective) modal: ModalDirective;
	resolveFn: (value?: MainAlertAction) => void | PromiseLike<void>;
	info: MainAlert = {};
	subscription: Subscription;
	config: ModalOptions = {
		class: 'modal-sm',
		animated: true,
		ignoreBackdropClick: true
	};

	constructor(
		private modalService: BsModalService,
		private mcmService: MainConfirmationModalService
	) { }

	ngOnInit() {
		this.subscription = this.mcmService.alertSubject
			.subscribe((obj) => {
				this.resolveFn = obj.resolve;
				this.info = Object.assign({}, this.getDefaultAlert(), obj.info);
				this.openModal();
			});
	}

	openModal() {
		this.modal.show();
	}

	confirm() {
		this.modal.hide();
		this.resolveFn({
			accepted: true,
			canceled: false
		});
	}

	decline() {
		this.modal.hide();
		this.resolveFn({
			accepted: false,
			canceled: true
		});
	}

	handler(name: string, $event: ModalDirective) {
		if (name === 'hidden') {
			this.resolveFn({
				accepted: false,
				canceled: true
			});
		}
	}

	getDefaultAlert(): MainAlert {
		return {
			title: '',
			body: '¿Estás seguro de realizar la acción?',
			acceptButtonColor: 'primary',
			cancelButton: true,
			cancelButtonColor: 'light',
			important: false
		};
	}
}
