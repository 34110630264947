import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Alert, AlertResolver } from '../../../../../shared/models/application/alert';
import { ToastNotificationService } from '../../service/toast-notification.service';

@Component({
	selector: 'app-toast-notification-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {
	list: { resolver: AlertResolver, alert: Alert }[] = [];
	toastSubscription: Subscription;

	constructor(
		private toastService: ToastNotificationService
	) { }

	ngOnInit() {
		this.listenToasts();
	}

	ngOnDestroy() {
		if (this.toastSubscription) {
			this.toastSubscription.unsubscribe();
		}
	}

	listenToasts() {
		this.toastSubscription = this.toastService.toastNotification
			.subscribe(data => {
				this.list.push(data);
			});
	}

	handleToastClosed($event: { alert: Alert, index: number }) {
		this.list.splice($event.index, 1);
	}

}
