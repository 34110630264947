import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../authentication/authentication.service';

@Injectable({
	providedIn: 'root'
})
export class NoAuthGuard implements CanActivateChild {
	constructor(
		private authService: AuthenticationService,
		private router: Router
	) { }

	canActivateChild(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const isLoggedIn: boolean = this.authService.isLoggedIn();

		const ruta = state.url;
		const fragments=ruta.split('/');

		if(fragments.includes('visit')) {
			return this.isVisit(isLoggedIn, ruta);
		}

		if (!isLoggedIn) {
			return true;
		} else {
			this.router.navigate(['/']);
			return false;
		}
	}

	private isVisit(isLoggedIn: boolean, ruta: string): boolean {
		const user = this.authService.getUser();
		if(isLoggedIn && user.level === "5"){
			this.router.navigate([`/admin-enterprise${ruta}`]);
			return false;
		}

		return true;
	}

}
