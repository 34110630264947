import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { MainConfirmationModalModule } from './global-components/main-confirmation-modal/main-confirmation-modal.module';
import { ToastNotificationModule } from './global-components/toast-notification/toast-notification.module';
import { DEFAULT_TIMEOUT, HttpErrorInterceptor } from './interceptors/error.interceptor';
import { HttpTokenInterceptor } from './interceptors/http-token.interceptor';
import { CustomLoader } from './translate/custom-loader';
import { ImgVisualizerComponent } from './global-components/img-visualizer/img-visualizer.component';


@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: CustomLoader
			}
		}),
		MainConfirmationModalModule,
		ToastNotificationModule
	],
	providers: [
		CookieService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpTokenInterceptor,
			multi: true
		}, {
			provide: DEFAULT_TIMEOUT,
			useValue: 30000
		}, {
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorInterceptor,
			multi: true
		}
	],
	exports: [
		MainConfirmationModalModule,
		ToastNotificationModule
	]
})
export class CoreModule { }
