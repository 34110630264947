import { Injectable } from "@angular/core";
import * as io from "socket.io-client";
import { environment } from "src/environments/environment";
import { Observable, Subject } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../authentication/authentication.service';

@Injectable({
  providedIn: "root"
})
export class SocketService {
  private endpoint = environment.apiUrl + '/historicals-scanner'
  private socket = io(environment.socket , {path: `${environment.socketContext}`});
  // private socket = io(environment.socket);
  idParkingLot: number;
  lastRegister: any;
  changeDetection: Subject<any>

  constructor(
  private http: HttpClient,
  private authService: AuthenticationService
  ) {
    this.changeDetection = new Subject();
    console.log(environment.socket, 'socket-edpoint')
  }

  scanner(): Subject<MessageEvent> {
    let observable = new Observable(observer => {
      this.socket.on(`scanner/${this.authService.getUser().secId}`, data => {
        this.lastRegister = data;
        observer.next(data);
        this.changeDetection.next(data)
      });
      return () => {
        this.socket.disconnect();
      };
    });

    // We define our Observer which will listen to messages
    // from our other components and send messages back to our
    // socket server whenever the `next()` method is called.
    let observer = {
      next: (data: any) => {
        this.socket.emit("scanner", data);
      }
    };

    // we return our Subject which is a combination
    // of both an observer and observable.
    return Subject.create(observer, observable);
  }

  disconectSocket(){
    this.socket.close()
  }

  register() {
    return this.lastRegister;
  }

  getAllFree(id: string) : Observable<any> {
    return this.http.get<any>(`${this.endpoint}/${id}/free`).pipe(
      map( res => res)
    )
  }
  getHistorical(id: string) : Observable<any> {
    return this.http.get<any>(`${this.endpoint}/parking-lot/${id}`).pipe(
      map( res => res.data)
    )
  }

  updateAccess(id: string | number, data: any): Observable<any> {
    return this.http.put<any>(`${this.endpoint}/${id}`, data).pipe(
      map( res => {
        return res
      })
    )
  }

  freeGaf(id: number){
	return this.http.put<any>(`${this.endpoint}/${id}/exit`, {id: id}).pipe(
		map( res => {
		  return res
		})
	  )
  }
}
