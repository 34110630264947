import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ItemComponent } from './components/item/item.component';
import { ListComponent } from './components/list/list.component';

@NgModule({
	declarations: [ListComponent, ItemComponent],
	imports: [
		CommonModule,
		TranslateModule,
		AlertModule.forRoot()
	],
	exports: [
		ListComponent,
		ItemComponent
	],
	entryComponents: [ItemComponent]
})
export class ToastNotificationModule { }
