export const API_ERRORS = {
	GENERAL_ERROR: '1000', // Server error
	GENERAL_ERROR_2: '1001', // Server error
	GENERAL_ERROR_3: '1002', // Server error
	UNAUTHORIZED: '1003', // No authorized to make the request
	NO_TOKEN_PROVIDED: '1004', // No token provided
	EXPIRED_TOKEN: '1005', // The token has expired
	NO_ACCESS: '1006', // User have no access to perform the action
	VALIDATION_ERROR: '1007', // Server validation error
	MAIL_SEND_ERROR: '1008', // Error sendind email
	MAIL_SEND_ERROR_2: '1009', // Error sending email

	// User Errors
	USER_ALREADY_EXISTS: '2000', // User already exists (email, username)
	USER_NOT_FOUND: '2001', // User not found or has been deleted
	USER_WRONG_CREDENTIALS: '2002', // Wrong credentials, user, password
	USER_NOT_ACTIVE: '2003', // The user is not active
	USER_EMAIL_NOT_FOUND: '2004', // User not found by email
	USER_WRONG_PASSWORD: '2005', // User wrong current password

	// Roles Errors
	ROLE_ALREADY_EXISTS: '2100', // Role already exists
	ROLE_NOT_FOUND: '2101', // Role not found or has been deleted

	// Modules Errors
	MODULE_NOT_FOUND: '2200', // Module not found

	PARKING_LOT_NOT_FOUND: '2500',
	PARKING_LOT_ALREADY_EXISTS: '2501',
	PARKING_LOT_IMAGE_NOT_EXISTS: '2502',
	PARKING_LOT_IMAGE_NOT_BELONG: '2503',

	// Promotions
	PROMOTION_NOT_FOUND: '2600',
	PROMOTION_ALREADY_EXISTS: '2601',
	PROMOTION_IMAGE_NOT_EXISTS: '2602',
	PROMOTION_IMAGE_NOT_BELONG: '2603'
};
