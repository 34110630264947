import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../authentication/authentication.service';
import { ToastNotificationService } from '../../global-components/toast-notification/service/toast-notification.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {
	constructor(
		private authService: AuthenticationService,
		private router: Router,
		private toastService: ToastNotificationService
	) { }

	canActivateChild(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const isLoggedIn: boolean = this.authService.isLoggedIn();
		
		if (isLoggedIn) {
			const firstLogin = this.authService.getUser().firstLogin;

			if (firstLogin) {
				if (state.url !== '/password-change' && state.url !== '/add-my-building') {
					this.router.navigate(['/password-change']);
					this.toastService.show({
						type: 'warning',
						translate: {
							key: 'errors.change_password'
						},
						timeout: 6000,
						close: true
					});
					return false;
				}
			}else{
				if (state.url === '/add-my-building') {
					this.router.navigate(['/home']);
				}
			}

			return true;
		} else {
			this.router.navigate(['/login']);
			return false;
		}
	}

}
