/**
 * Prod
 */
const host = 'https://app.axes-control.com';
const socketContext = '/axesweb-backend/socket.io/';

export const environment = {
	production: false,
	appName: 'axes',
	apiUrl: `${host}/axesweb-backend/api`,
	socket: `${host}`,
	socketContext: `${socketContext}`
};