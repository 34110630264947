import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { MalihuScrollbarService } from "ngx-malihu-scrollbar";
import { SocketService } from "./core/http/socket/socket.service";
import { Router } from "@angular/router";
import { SocketBuildingService } from './core/http/socket/socket-building.service';
import { AuthenticationService } from './core/authentication/authentication.service';
import { SocketLogbookService } from './core/http/socket/socket-logbook.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  title = "axesweb-frontend";

  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
    private mScrollbarService: MalihuScrollbarService,
    private socketService: SocketService,
    private socketBuldingService: SocketBuildingService,
    private socketVisitService: SocketLogbookService,
    private router: Router,
    private authService: AuthenticationService
  ) {
    this.loadLanguage();
  }

  ngOnInit() {
    this.mScrollbarService.initScrollbar(document.body, {
      axis: "y",
      theme: "dark-3"
    });

    if (this.authService.getUser()) {
      this.conectSockets();
    }
    this.authService.conectSocket.subscribe(socket => {
      if (socket) {
        this.conectSockets()
      } else {
        this.unsubscribeSockets();
        //change when
        window.location.reload()
      }
    })
  }
  conectSockets() {
    this.socketService.scanner().subscribe((x: any) => {
      if (this.socketBuldingService.getSatusSocket() && this.authService.getUser()) {
        this.router.navigate([`/scanner/parking/${x.parkingLot.id}`]);
      }
      if (this.socketVisitService.getSatusSocket() && this.authService.getUser()) {
      }
    });
    this.socketBuldingService.scanner().subscribe((x: any) => {
      if (this.socketBuldingService.getSatusSocket() && this.authService.getUser()) {
        this.router.navigate([`/scanner/building/${x.building.id}`])
      }
    });
    this.socketVisitService.scanner().subscribe((x: any) => {
    });
    this.socketVisitService.scannerNewRegisterBitacora().subscribe((x: any) => {
    });
  }

  unsubscribeSockets() {
    this.socketService.scanner().unsubscribe();
    this.socketBuldingService.scanner().unsubscribe();
    this.socketBuldingService.disconectSocket();
    this.socketVisitService.scanner().unsubscribe();
    this.socketVisitService.scannerNewRegisterBitacora().unsubscribe();
    this.socketVisitService.disconectSocket();
  }

  loadLanguage() {
    let lang = this.cookieService.get("lang");
    const userLang = navigator.language.split("-")[0] || "en";

    if (!lang) {
      this.cookieService.set("lang", userLang);
      lang = this.cookieService.get("lang");
    }

    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
  }

  onActivate(event: any) {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }
}
