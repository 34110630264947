import { ComponentFactoryResolver, Inject, Injectable, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Alert, AlertAction, AlertRef, AlertResolver } from '../../../../shared/models/application/alert';
import { ItemComponent } from '../components/item/item.component';

@Injectable({
	providedIn: 'root'
})
export class ToastNotificationService {
	toastNotification: Subject<{ resolver: AlertResolver, alert: Alert }>;

	constructor(
		private translate: TranslateService,
		@Inject(ComponentFactoryResolver)
		private factoryResolver: ComponentFactoryResolver
	) {
		this.toastNotification = new Subject();
	}

	show(alert: Alert): Promise<AlertAction> {
		return new Promise((resolver, reject) => {
			alert = this.translateMessage(alert);

			this.toastNotification.next({ resolver, alert });
		});
	}

	showInTemplate(alert: Alert, template: ViewContainerRef): AlertRef {
		let instance: ItemComponent;

		alert = this.translateMessage(alert);
		this.hideFromTemplate(template);

		const factory = this.factoryResolver.resolveComponentFactory(ItemComponent);
		const component = factory.create(template.injector);
		instance = component.instance;

		instance.item = alert;
		template.insert(component.hostView);

		return {
			hide: () => {
				instance.alertComponent.close();
			},
			onHidden: () => {
				return new Promise((resolve, reject) => {
					instance.resolver = resolve;
				});
			}
		};
	}

	hideFromTemplate(template: ViewContainerRef) {
		if (template && template.length) {
			template.clear();
		}
	}

	private translateMessage(alert: Alert): Alert {
		if (alert.translate && !alert.message) {
			alert.message = this.translate.instant(alert.translate.key, alert.translate.params);
		}

		return alert;
	}
}
