import { Injectable } from "@angular/core";
import * as io from "socket.io-client";
import { environment } from "src/environments/environment";
import { Observable, Subject } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class SocketBuildingService {
  private endpoint = environment.apiUrl + '/historicals-scanner-building'
  private socket = io(environment.socket , {path: `${environment.socketContext}`});
  socketStatus: string = (environment.appName || '') + '-socket';
  socketStatusDetection: Subject<boolean>;
  buildingId: number;
  lastRegister: any;
  changeDetection: Subject<any>

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {
    this.changeDetection = new Subject();
    this.socketStatusDetection = new Subject();
    console.log(environment.socket, 'socket-building-environment')
    console.log(this.socket)
  }

  scanner(): Subject<MessageEvent> {
    let observable = new Observable(observer => {
      this.socket.on(`scannerBuilding/${this.authService.getUser().secId}`, data => {
        this.lastRegister = data;
        observer.next(data);
        this.changeDetection.next(data)
      });
      return () => {
        this.socket.close();
      };
    });

    // We define our Observer which will listen to messages
    // from our other components and send messages back to our
    // socket server whenever the `next()` method is called.
    let observer = {
      next: (data: any) => {
        this.socket.emit("scanner", data);
      }
    };

    // we return our Subject which is a combination
    // of both an observer and observable.
    return Subject.create(observer, observable);
  }

  disconectSocket() {
    this.socket.close()
  }

  register() {
    return this.lastRegister;
  }

  getAllFree(id: string,type?:boolean): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/${id}/free/${type?'scannerID':'scanner'}`).pipe(
      map(res => res)
    )
  }
  getHistorical(id: string, type?: string, start?: string, end?: string): Observable<any> {
    const url = start && end ? 
    `${this.endpoint}/building/${type ? type : 'scanner'}/${id}?start=${start}&end=${end}`:
    `${this.endpoint}/building/${type ? type : 'scanner'}/${id}` ;
    return this.http.get<any>(url).pipe(
      map(res => res.data),
      map(data=>{
        const historical=data.historical.map((element,index) => {
          return {...element,index: index+1 };
        });
        return {...data,historical};
      })
    )
  }

  rekognition(id:number){
    return this.http.get<any>(`${this.endpoint}/getRekognition/${id}`).pipe(
      map(res => res)
    );
  }

  compareFaces(formData){
    return this.http.post<any>(`${this.endpoint}/compareFaces`, formData).pipe(
      map(res => {
        return res;
      })
    );
  }

  getTextImage(image: FormData): Promise<any> {
    return fetch(`${this.endpoint}/getDataImage`, {
      method: 'POST',
      body: image,
      redirect: 'follow',
      headers:{
        Authorization:'Bearer '+JSON.parse(localStorage.getItem('axes-token')).key
      }
    }).then(response => response.json())
      .catch(error => console.log('error', error));
  }

  getTextImageQR(data: FormData): Promise<any> {
    return fetch(`${this.endpoint}/getDataImageWithInvitation`, {
      method: 'POST',
      body: data,
      redirect: 'follow',
      // headers:{
      //   Authorization:'Bearer '+JSON.parse(localStorage.getItem('axes-token')).key
      // }
    }).then(response => response.json())
      .catch(error => console.log('error', error));
  }

  updateAccess(id: string | number, data: any): Observable<any> {
    return this.http.put<any>(`${this.endpoint}/${id}`, data).pipe(
      map(res => {
        return res
      })
    )
  }

  freeGaf(id: number) {
    return this.http.put<any>(`${this.endpoint}/${id}/exit`, { id: id }).pipe(
      map(res => {
        return res
      })
    )
  }

  verify(data: any) {
    return this.http.post<any>(`${this.endpoint}/verify`, data).pipe(
      map(res => {
        return res
      })
    )
  }

  registerEntry(formData) {
    return this.http.post<any>(`${this.endpoint}/registerVisit`, formData).pipe(
      map(res => {
        return res
      })
    );
  }

  getSatusSocket(): boolean {
    const value = localStorage.getItem(this.socketStatus);
    return value ? JSON.parse(value) : null;
  }

  setStatusSocket(status: boolean) {
    localStorage.setItem(this.socketStatus, JSON.stringify(status));
    this.socketStatusDetection.next(status);
  }
}
