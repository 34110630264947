import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { interval } from 'rxjs';
import { fade } from '../../../../../shared/animations/fade';
import { Alert, AlertResolver } from '../../../../../shared/models/application/alert';

@Component({
	selector: 'app-toast-notification',
	templateUrl: './item.component.html',
	styleUrls: ['./item.component.scss'],
	animations: [fade]
})
export class ItemComponent implements OnInit, AfterViewInit {
	@ViewChild(AlertComponent) alertComponent: AlertComponent;
	@Input() index: number;
	@Input() item: Alert;
	@Input() resolver: AlertResolver;
	@Input() isTop: boolean = false;
	@Output() onClose: EventEmitter<{ alert: Alert, index: number }> = new EventEmitter();
	@Output() onClosed: EventEmitter<{ alert: Alert, index: number }> = new EventEmitter();
	barWidth: number;
	interval: number = 20;

	constructor() { }

	ngOnInit() {
		if (this.hasTimeout()) {
			this.barWidth = 100;

			const source = interval(this.interval).subscribe(val => {
				const percentage = (((val * this.interval) / this.item.timeout) * 100);

				this.barWidth = 100 - percentage;

				if (this.barWidth < 0) {
					source.unsubscribe();
				}
			});
		}
	}

	ngAfterViewInit() {
	}

	hasTimeout(): boolean {
		return typeof this.item.timeout === 'number';
	}

	handleClose($event: AlertComponent) {
		this.onClose.emit({ alert: this.item, index: this.index });
	}

	handleClosed($event: AlertComponent) {
		this.onClosed.emit({ alert: this.item, index: this.index });

		if (typeof this.resolver === 'function') {
			this.resolver({ closed: true });
		}
	}

}
