import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IAuthToken, IAuthUser } from '../../shared/models/application/auth-user';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {
	sessionName: string = (environment.appName || '') + '-current-user';
	tokenName: string = (environment.appName || '') + '-token';
	userChanges: Subject<IAuthUser>;
	conectSocket: Subject<boolean>;

	constructor() {
		this.userChanges = new Subject();
		this.conectSocket = new Subject();
	}

	getUser(): IAuthUser {
		const value = localStorage.getItem(this.sessionName);
		return value ? JSON.parse(value) : null;
	}

	getToken(): IAuthToken {
		const value = localStorage.getItem(this.tokenName);
		return value ? JSON.parse(value) : null;
	}

	setUser(user: IAuthUser) {
		localStorage.setItem(this.sessionName, JSON.stringify(user));
		this.userChanges.next(Object.assign({}, user));
		this.conectSocket.next(true);
	}

	setToken(token: IAuthToken) {
		localStorage.setItem(this.tokenName, JSON.stringify(token));
	}

	setUserAndToken(user: IAuthUser, token: IAuthToken) {
		this.setUser(user);
		this.setToken(token);
	}

	destroySession() {
		localStorage.removeItem(this.sessionName);
		localStorage.removeItem(this.tokenName);
		this.conectSocket.next(false)
	}

	isLoggedIn(): boolean {
		return this.getToken() !== null;
	}

}
