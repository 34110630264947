import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { InterceptorOptions } from "../../shared/models/application/interceptor-options";
import { AuthenticationService } from "../authentication/authentication.service";
import { ToastNotificationService } from "../global-components/toast-notification/service/toast-notification.service";

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthenticationService,
    private cookieService: CookieService,
    private toastService: ToastNotificationService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    const lang = this.cookieService.get("lang");
    const userLang = navigator.language.split("-")[0] || "en";

    const metaHeader = req.headers.get("meta")
      ? JSON.parse(req.headers.get("meta"))
      : {};
    const options: InterceptorOptions = Object.assign(
      {},
      this.getDefaultOptions(),
      metaHeader
    );

    if (token) {
      req = req.clone({ headers: req.headers.set("Authorization", token.key) });
    }

    req = req.clone({ headers: req.headers.set("Accept", "application/json") });
    req = req.clone({ headers: req.headers.set("Accept-Language", lang) });
    req = req.clone({ withCredentials : false})

    if (!environment.production) {
      console.log("req--->>>", req);
    }

    if (req.headers.get("meta")) {
      req.headers.delete("meta");
    }

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && !environment.production) {
          console.log("event--->>>", event);
          // if (event.body && event.body.message) {
          if (event.body && event.body.message && options.showToastSuccess) {
            this.toastService.show({
              type: "success",
              close: true,
              timeout: 6000,
              message: event.body.message
            });
          }
        }

        return event;
      })
    );
  }

  private getDefaultOptions(): InterceptorOptions {
    return {
      showToastSuccess: false
    };
  }
}
