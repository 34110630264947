import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable()
export class CustomLoader implements TranslateLoader {
	constructor(
		private injector: Injector
	) { }

	getTranslation(lang: string): Observable<any> {
		const http = this.injector.get(HttpClient);
		if(lang) {
			return http.get(`./assets/i18n/${lang}.json`);
		};

		return http.get(`./assets/i18n/es.json`);
	}
}
